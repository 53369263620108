<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

    <v-row>
      
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >        
        <base-material-tabs
          v-model="tab"
          background-color="transparent"
          
          color="warning"
          icons-and-text
        >
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
          >
            {{ tab.text }}
            <v-icon v-text="tab.icon" />
          </v-tab>

          <v-tabs-items
            v-model="tab"
            class="pt-12 transparent"
          >
            <v-tab-item>
              <v-card class="mt-0 px-5">
                <v-card-text>
                  <base-subheading subheading="Sesuai dengan ND-145/DPKS/2020 pokok nota dalam Pelaksanaan Penyesuaian Sistem Kerja Dalam Tatanan Normal Baru Pada BPDPKS, kami sampaikan hal-hal sebagai berikut:">

                  </base-subheading>
                    <div class="body-2">
                      <ol>
                        <li>Sebagai Langkah pelaksanaan transisi dalam tatanan normal baru yang produktif dan aman dari Covid-19 di lingkungan Kemenkeu serta dalam upaya mencegah terjadinnya gelombang selanjutnnya atas Covid-19, pelaksanaan sistem kerja di BPDPKS akan dilaksanakan melalui fleksibilitas dalam pengaturan lokasi kerja, yang meliputi:
                        <ol type="a">
                            <li>Pelaksanaan tugas kedinasan di kantor (Work From Office/WFO); dan/atau</li>
                            <li>Pelaskanaan tugas kedinasan dengan menerapkan mekanisme fleksibilitas tempat kerja (Flexible Working Space/FWS)</li>
                       </ol>
                        </li>
                        <li>Dilakukan kebijakan/pengaturan penahapan di lingkup BPDPKS dengan ketentuan sebagai berikut:
                          <ol type="a">
                            <li>Tahap I: dilaksanakan selama 28 hari sejak SE-22/MK.1/2020 berlaku atau s.d 2 Juli 2020 maksimal pegawai yang melakukan WFO yaitu 15%;</li>
                            <li>Tahap II: dilaksanakan dalam hal Tahap I tidak terjadi penambahan kasus (terutama ODP, PDP dan Positif Covid-19) di Jakarta, selama periode 28 hari berikutnnya sejak selesai Tahap I atau s.d. 30 Juli 2020, maksimal pegawai yang melaksanakan WFO yaitu 30%;</li>
                            <li>Tahap III: dilaksanakan dalam hal selama Tahap II tidak terjadi penambahan kasus (terutama ODP, PDP dan Positif Covid-19) di Jakarta, selama periode 28 hari berikutnya dan selanjutnya sejak selesainya tahap II atau s.d. 27 Agustus 2020, maksimal pegawai yang melakukan WFO yaitu 50%.</li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                </v-card-text>
              </v-card>
              <v-layout justify-center align-center>
                <div>
                  <v-btn color="blue" rounded to="/wfh/absen" >
                    Buka Absen
                  </v-btn>
                  &nbsp;
                  <v-btn color="green" rounded to="/repwork/list" >
                    Buka Laporan Pekerjaan
                  </v-btn>
                </div>
              </v-layout>
            </v-tab-item>

          </v-tabs-items>

          

        </base-material-tabs>
      </v-col>
    </v-row>

    
    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
        <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
          <v-layout justify-center align-center>
            <v-card
              width="70vW"
              class="pa-md-2 mx-lg-auto"
              outlined
            >
              <v-list-item three-line centered>
                <v-list-item-content>
                  <div class="overline mb-4">Processing</div>
                  <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                    ></v-progress-linear>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>

          </v-layout>
        </v-container>
      </v-dialog>

  </v-container>
</template>

<script>
import moment from 'moment'

  export default {
    name: 'DashboardPanels',

    data: () => ({
      showInfo: false,
      isAdmin: false,
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'Informasi',
          icon: 'mdi-information',
        }
      ],
      isLoading: false,
      greeting: '',
      dataUser: null
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this

      let sUser = localStorage.getItem('user')
      vm.dataUser = JSON.parse(sUser)
      if (vm.dataUser !== null) {
        // console.log(vm.dataUser)
        if(vm.dataUser.role !== null) {
          if(vm.dataUser.role.id === 1) {
            vm.isAdmin = true
          }
        }
      }
    },
    destroyed () {
    },
    mounted () {
      const vm = this
      vm.showInfo = true // show popup message
    },
    methods: {

    }
  }
</script>
